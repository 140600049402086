import { ApiCollection }                  from 'Collections/ApiCollection';
import RiskAssessmentModel                from 'Models/intervention/RiskAssessmentModel';
import ContractIterationBillingGroupModel from 'Models/sales/ContractIterationBillingGroupModel';
import ContractIterationItemEventModel    from 'Models/sales/ContractIterationItemEventModel';
import ContractIterationModel             from 'Models/sales/ContractIterationModel';
import ContractIterationReconductionModel from 'Models/sales/ContractIterationReconductionModel';
import ContractIterationStatusModel       from 'Models/sales/ContractIterationStatusModel';
import QuotationBillingGroupModel         from 'Models/sales/QuotationBillingGroupModel';
import QuotationItemEventModel            from 'Models/sales/QuotationItemEventModel';
import QuotationModel                     from 'Models/sales/QuotationModel';
import QuotationStatusModel               from 'Models/sales/QuotationStatusModel';
import ReconductabilityModel              from 'Models/sales/ReconductabilityModel';

export const quotationPreviewableStatuses: SalesQuotationStatusReference[] = [
	'being_created',
	'in_validation',
	'to_regularize',
	'validated',
];

export const contractIterationPreviewableStatuses: SalesContractIterationStatusReference[] = [
	'being_created',
	'in_validation',
	'validated',
];

export const Rights = {
	contractIteration: {
		activitiesVatsAreUpdatable: (contractIterationStatus: ContractIterationStatusModel) => {
			return contractIterationStatus.reference === 'being_created'
				|| contractIterationStatus.reference === 'in_validation'
				|| contractIterationStatus.reference === 'validated'
				|| contractIterationStatus.reference === 'sent_to_client'
				|| contractIterationStatus.reference === 'validated_by_client'
				|| contractIterationStatus.reference === 'pre_activated';
		},
		eventsAreUpdatable: (contractIterationStatus: ContractIterationStatusModel) => {
			return contractIterationStatus.reference === 'being_created'
				|| contractIterationStatus.reference === 'in_validation'
				|| contractIterationStatus.reference === 'validated'
				|| contractIterationStatus.reference === 'sent_to_client'
				|| contractIterationStatus.reference === 'validated_by_client';
		},
		isActivableOrPreActivable: (contractIteration: ContractIterationModel, contractIterationBillingGroup: ContractIterationBillingGroupModel | null, contractIterationItemEventCollection: ApiCollection<ContractIterationItemEventModel>) => {
			if (!contractIterationBillingGroup?.id || contractIterationItemEventCollection.length < 1) {
				return false;
			}
			return true;
		},
		isAmendmentCreatable: (contractIterationStatus: ContractIterationStatusModel) =>
			contractIterationStatus.reference === 'activated',
		isArchivable: (contractIteration: ContractIterationModel, contractIterationStatus: ContractIterationStatusModel) => {
			return !contractIteration.archived
				&& (
					contractIterationStatus.reference === 'canceled'
					|| contractIterationStatus.reference === 'closed'
				);
		},
		isAutoliquidationUpdatable: (contractIterationStatus: ContractIterationStatusModel) => {
			return contractIterationStatus.reference === 'being_created'
				|| contractIterationStatus.reference === 'in_validation'
				|| contractIterationStatus.reference === 'validated'
				|| contractIterationStatus.reference === 'sent_to_client'
				|| contractIterationStatus.reference === 'validated_by_client'
				|| contractIterationStatus.reference === 'pre_activated'
				|| contractIterationStatus.reference === 'activated'
				|| contractIterationStatus.reference === 'terminated';
		},
		isPreviewable: (contractIterationStatus: ContractIterationStatusModel) => {
			return !!contractIterationPreviewableStatuses.find(status => status === contractIterationStatus.reference);
		},
		isReconductible: (
			contractIteration: ContractIterationModel,
			contractIterationReconductionCollection: ApiCollection<ContractIterationReconductionModel>,
			reconductability: null | ReconductabilityModel,
		) => {
			// 1. status actif OU terminé
			// 2. reconduction tacite
			// 3. 0 reconductions
			// 4. reconductible à tout moment

			if (!contractIteration.isLoaded) {
				return false;
			}

			if (
				contractIteration.contractIterationStatus.reference !== 'activated'
				&& contractIteration.contractIterationStatus.reference !== 'terminated'
			) {
				return false;
			}

			if (!reconductability) {
				return false;
			}

			return contractIterationReconductionCollection.reduce((canceled, contractIterationReconduction) => {
				return canceled
					&& contractIterationReconduction.contractIterationReconduction.contractIterationStatus.reference === 'canceled';
			}, true);
		},
		isRiskAssessmentUpdatable: (contractIterationStatus: ContractIterationStatusModel, riskAssessment: RiskAssessmentModel) => {
			return (
				riskAssessment.id
				&& contractIterationStatus.reference !== 'closed'
				&& contractIterationStatus.reference !== 'terminated'
			) || (
				!riskAssessment.id
				&& (
					Rights.contractIteration.isUpdatable(contractIterationStatus)
					|| contractIterationStatus.reference === 'suspended'
				)
			);
		},
		isUpdatable: (contractIterationStatus: ContractIterationStatusModel) => {
			return contractIterationStatus.reference === 'being_created'
				|| contractIterationStatus.reference === 'in_validation'
				|| contractIterationStatus.reference === 'validated'
				|| contractIterationStatus.reference === 'sent_to_client'
				|| contractIterationStatus.reference === 'validated_by_client'
				|| contractIterationStatus.reference === 'pre_activated';
		},
	},
	quotation: {
		activitiesVatsAreUpdatable: (quotationStatus: QuotationStatusModel) => {
			return quotationStatus.reference === 'being_created'
				|| quotationStatus.reference === 'in_validation'
				|| quotationStatus.reference === 'validated'
				|| quotationStatus.reference === 'sent_to_client'
				|| quotationStatus.reference === 'validated_by_client'
				|| quotationStatus.reference === 'pre_activated';
		},
		eventsAreUpdatable: (quotationStatus: QuotationStatusModel) => {
			return quotationStatus.reference === 'being_created'
				|| quotationStatus.reference === 'in_validation'
				|| quotationStatus.reference === 'validated'
				|| quotationStatus.reference === 'sent_to_client'
				|| quotationStatus.reference === 'validated_by_client'
				|| quotationStatus.reference === 'paid';
		},
		isActivableOrPreActivable: (quotation: QuotationModel, quotationBillingGroup: QuotationBillingGroupModel | null, quotationItemEventCollection: ApiCollection<QuotationItemEventModel>) => {
			if (!quotationBillingGroup?.id || quotationItemEventCollection.length < 1) {
				return false;
			}
			return true;
		},
		isArchivable: (quotation: QuotationModel, quotationStatus: QuotationStatusModel) => {
			return !quotation.archived
				&& (
					quotationStatus.reference === 'canceled'
					|| quotationStatus.reference === 'closed'
				);
		},
		isAutoliquidationUpdatable: (quotationStatus: QuotationStatusModel) => {
			return quotationStatus.reference === 'being_created'
				|| quotationStatus.reference === 'in_validation'
				|| quotationStatus.reference === 'validated'
				|| quotationStatus.reference === 'sent_to_client'
				|| quotationStatus.reference === 'validated_by_client'
				|| quotationStatus.reference === 'to_regularize'
				|| quotationStatus.reference === 'pre_activated'
				|| quotationStatus.reference === 'activated'
				|| quotationStatus.reference === 'terminated';
		},
		isPreviewable: (quotationStatus: QuotationStatusModel) => {
			return !!quotationPreviewableStatuses.find(status => status === quotationStatus.reference);
		},
		isRiskAssessmentUpdatable: (quotationStatus: QuotationStatusModel, riskAssessment: RiskAssessmentModel) => {
			return (
				riskAssessment.id
				&& quotationStatus.reference !== 'closed'
				&& quotationStatus.reference !== 'terminated'
			) || (
				!riskAssessment.id
				&& (
					Rights.quotation.isUpdatable(quotationStatus)
					|| quotationStatus.reference === 'suspended'
					|| quotationStatus.reference === 'paid'
				)
			);
		},
		isUpdatable: (quotationStatus: QuotationStatusModel) => {
			return quotationStatus.reference === 'being_created'
				|| quotationStatus.reference === 'in_validation'
				|| quotationStatus.reference === 'validated'
				|| quotationStatus.reference === 'sent_to_client'
				|| quotationStatus.reference === 'validated_by_client'
				|| quotationStatus.reference === 'to_regularize'
				|| quotationStatus.reference === 'pre_activated';
		},
	},
};
