import PlanningModel               from 'Models/intervention/PlanningModel';
import ResourceModel               from 'Models/intervention/ResourceModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('PlanningResource')
@model.urnResource('planning_resource')
@doc.path('/planning_resources/{?id}')
export default class PlanningResourceModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'planning': id;
		'planning.ownerSubPartition': id;
		'planning.ownerSubPartition.partitionUrn': Urn;
		'planning.ownerSubPartition.subPartitionUrn': Urn;
		'resource': id;
		'resource.resourceType': id;
		'resource.resourceType.reference': InterventionResourceTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
	}> = {};

	@doc.model(PlanningModel) declare planning: PlanningModel;
	@doc.number declare position: number;
	@doc.model(ResourceModel) declare resource: ResourceModel;
}